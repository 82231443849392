import { ContactSection } from '../components/ContactSection'
import { Container } from '../components/Container'
import { FadeIn } from '../components/FadeIn'
import { GridList, GridListItem } from '../components/GridList'
import { GridPattern } from '../components/GridPattern'
import { PageIntro } from '../components/PageIntro'
import { SectionIntro } from '../components/SectionIntro'
import { StylizedImage } from '../components/StylizedImage'
import { TagList, TagListItem } from '../components/TagList'
import imageLaptop from '../images/laptop.jpg'
import imageMeeting from '../images/meeting.jpg'
import imageWhiteboard from '../images/whiteboard.jpg'

function Section({ title, image, children }) {
  return (
    <Container className="group/section [counter-increment:section]">
      <div className="lg:flex lg:items-center lg:justify-end lg:gap-x-8 lg:group-even/section:justify-start xl:gap-x-20">
        <div className="flex justify-center">
          <FadeIn className="w-[33.75rem] flex-none lg:w-[45rem]">
            <StylizedImage
              {...image}
              sizes="(min-width: 1024px) 41rem, 31rem"
              className="justify-center lg:justify-end lg:group-even/section:justify-start"
            />
          </FadeIn>
        </div>
        <div className="mt-12 lg:mt-0 lg:w-[37rem] lg:flex-none lg:group-even/section:order-first">
          <FadeIn>
            <div
              className="font-display text-base font-semibold before:text-neutral-300 before:content-['/_'] after:text-neutral-950 after:content-[counter(section,decimal-leading-zero)]"
              aria-hidden="true"
            />
            <h2 className="mt-2 font-display text-3xl font-medium tracking-tight text-neutral-950 sm:text-4xl">
              {title}
            </h2>
            <div className="mt-6">{children}</div>
          </FadeIn>
        </div>
      </div>
    </Container>
  )
}

function Discover() {
  return (
    <Section title="Discover" image={{ src: imageWhiteboard }}>
      <div className="space-y-6 text-base text-neutral-600">
        <p>
        In the discovery phase, we delve deep into understanding your{' '}
          <strong className="font-semibold text-neutral-950">vision, goals,</strong> and{' '}
          <strong className="font-semibold text-neutral-950">requirements</strong>.
          We work closely with you to define the scope of the project, identify challenges, and explore{' '}
          <strong className="font-semibold text-neutral-950">potential solutions</strong>. This phase is all about laying a solid foundation for the development process, ensuring that everyone is aligned on the objectives and expectations.
        </p>
      </div>

      <h3 className="mt-12 font-display text-base font-semibold text-neutral-950">
        Included in this phase
      </h3>
      <TagList className="mt-4">
        <TagListItem>Requirement gathering</TagListItem>
        <TagListItem>Analysis</TagListItem>
        <TagListItem>Stakeholder interviews</TagListItem>
        <TagListItem>Market research</TagListItem>
        <TagListItem>Proofs-of-concept</TagListItem>
        <TagListItem>Prototyping and wireframing</TagListItem>
      </TagList>
    </Section>
  )
}

function Build() {
  return (
    <Section title="Build" image={{ src: imageLaptop, shape: 1 }}>
      <div className="space-y-6 text-base text-neutral-600">
        <p>
        Once the discovery phase is complete, we move into the build phase where the magic happens. Our team of experienced developers, designers, and engineers collaborate to turn concepts into reality. We follow agile methodologies to iteratively develop and refine the software, keeping you involved and informed every step of the way. Transparency and communication are at the core of our development process, ensuring that your feedback is incorporated seamlessly.
        </p>
      </div>

      <h3 className="mt-12 font-display text-base font-semibold text-neutral-950">
        Included in this phase
      </h3>
      <TagList className="mt-4">
        <TagListItem>Agile development sprints</TagListItem>
        <TagListItem>Continuous integration and testing</TagListItem>
        <TagListItem>UI/UX design</TagListItem>
        <TagListItem>Code review and optimization</TagListItem>
      </TagList>
    </Section>
  )
}

function Deliver() {
  return (
    <Section title="Deliver" image={{ src: imageMeeting, shape: 2 }}>
      <div className="space-y-6 text-base text-neutral-600">
        <p>
        {`The delivery phase marks the culmination of our efforts, where we unveil the finished product to you. But our commitment doesn't end there. We provide comprehensive support and maintenance services to ensure that your software operates smoothly in the real world. Whether it's deploying the software to your infrastructure or providing user training, we're here to ensure a seamless transition and ongoing success.`}
        </p>
      </div>

      
      <h3 className="mt-12 font-display text-base font-semibold text-neutral-950">
        Included in this phase
      </h3>
      <TagList className="mt-4">
        <TagListItem>Deployment and implementation</TagListItem>
        <TagListItem>User training and documentation</TagListItem>
        <TagListItem>Post-launch support and maintenance</TagListItem>
        <TagListItem>Performance monitoring and optimization</TagListItem>
      </TagList>
    </Section>
  )
}

function Values() {
  return (
    <div className="relative mt-24 pt-24 sm:mt-32 sm:pt-32 lg:mt-40 lg:pt-40">
      <div className="absolute inset-x-0 top-0 -z-10 h-[884px] overflow-hidden rounded-t-4xl bg-gradient-to-b from-neutral-50">
        <GridPattern
          className="absolute inset-0 h-full w-full fill-neutral-100 stroke-neutral-950/5 [mask-image:linear-gradient(to_bottom_left,white_40%,transparent_50%)]"
          yOffset={-270}
        />
      </div>

      <SectionIntro
        eyebrow="Our values"
        title="Balancing reliability and innovation"
      >
        <p>
        Welcome to Ognis Digital, where we believe in the seamless harmony between reliability and innovation. In the dynamic landscape of software development, we understand the critical importance of both consistency and progress. Our values are deeply rooted in this balance, shaping every aspect of our work, from ideation to execution.
        </p>
      </SectionIntro>

      <Container className="mt-24">
        <GridList>
          <GridListItem title="Consistency">
          We believe in delivering consistent results, time after time. Our processes are meticulously designed to ensure uniformity and quality in every project we undertake.
          </GridListItem>
          <GridListItem title="Trustworthiness">
          Trust is earned through reliability. We foster trust by delivering on our promises and consistently meeting or surpassing expectations.
          </GridListItem>
          <GridListItem title="Quality Assurance">
          Rigorous testing and quality assurance protocols are integral parts of our development process. We leave no stone unturned in ensuring the reliability and performance of our solutions.
          </GridListItem>
          <GridListItem title="Creativity">
          Innovation flourishes in an environment that encourages creativity and out-of-the-box thinking. We empower our team members to unleash their creativity and explore unconventional solutions.
          </GridListItem>
          <GridListItem title="Continuous Improvement">
          Innovation is not a one-time event but a continuous journey of improvement. We are committed to refining our processes, adopting emerging technologies, and learning from both successes and failures.
          </GridListItem>
          <GridListItem title="Future Focus">
          We keep our eyes firmly set on the future, anticipating trends and disruptions in the tech industry. By staying proactive and adaptable, we ensure that our solutions remain relevant and cutting-edge.
          </GridListItem>
        </GridList>
      </Container>
    </div>
  )
}

export const metadata = {
  title: 'Our Process',
  description:
    'We believe in efficiency and maximizing our resources to provide the best value to our clients.',
}

function Process() {
  return (
    <>
      <PageIntro eyebrow="Our process" title="How we work">
        <p>
        We believe in a streamlined approach to software development that ensures efficiency, quality, and client satisfaction. Our process revolves around three key phases: Discover, Build, and Deliver.
        </p>
      </PageIntro>

      <div className="mt-24 space-y-24 [counter-reset:section] sm:mt-32 sm:space-y-32 lg:mt-40 lg:space-y-40">
        <Discover />
        <Build />
        <Deliver />
      </div>

      <Values />

      <ContactSection />
    </>
  )
}

export default Process;