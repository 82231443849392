import React from 'react';
import { RootLayout } from '../components/RootLayout';
import { BrowserRouter as Router } from 'react-router-dom';

export const metadata = {
  title: {
    template: '%s - Ognis',
    default: 'Ognis Digital - Software Development Company for Innovative Solutions.',
  },
};

export default function Layout({ children }) {
  return (
    <RootLayout>{children}</RootLayout>
  );
}
