
import { Link } from 'react-router-dom';

import { ContactSection } from '../components/ContactSection'
import { Container } from '../components/Container'
import { FadeIn, FadeInStagger } from '../components/FadeIn'
import { List, ListItem } from '../components/List'
import { SectionIntro } from '../components/SectionIntro'
import { StylizedImage } from '../components/StylizedImage'
import { Testimonial } from '../components/Testimonial'
import logoSparcle from '../images/clients/sparcle/logo.png'

import logoPhp from '../images/technology/php.svg'
import logoLaravel from '../images/technology/laravel.svg'
import logoDotnet from '../images/technology/dotnet.svg'
import logoNodejs from '../images/technology/nodejs.svg'
import logoMysql from '../images/technology/mysql.svg'
import logoJavascript from '../images/technology/javascript.svg'
import logoReactjs from '../images/technology/reactjs.svg'
import logoVuejs from '../images/technology/vuejs.svg'
import logoMagento from '../images/technology/magento.svg'
import logoWordpress from '../images/technology/wordpress.svg'
import logoShopify from '../images/technology/shopify.svg'
import logoAws from '../images/technology/aws.svg'
import logoIos from '../images/technology/ios.svg'
import logoAndroid from '../images/technology/android.svg'
import logoReactnative from '../images/technology/reactnative.svg'

import imageLaptop from '../images/laptop.jpg'
import imageAbout from '../images/about.jpg'
// import { loadCaseStudies } from '../lib/mdx'

const technology = [
  ['PHP', logoPhp],
  ['Laravel', logoLaravel],
  ['ASP.NET', logoDotnet],
  ['NodeJS', logoNodejs],
  ['MySQL', logoMysql],
  ['JavaScript', logoJavascript],
  ['React JS', logoReactjs],
  ['Vue JS', logoVuejs],
  ['Magento', logoMagento],
  ['WordPress', logoWordpress],
  ['Shopify', logoShopify],
  ['AWS', logoAws],
  ['iOS', logoIos],
  ['Android', logoAndroid],
  ['React Native', logoReactnative],
]

function Clients() {
  return (
    <div className="mt-24 rounded-4xl bg-neutral-950 py-20 sm:mt-32 sm:py-32 lg:mt-56">
      <Container>
        <FadeIn className="flex items-center gap-x-8">
          <h2 className="text-center font-display text-sm font-semibold tracking-wider text-white sm:text-left">
          Explore our leading technology solutions
          </h2>
          <div className="h-px flex-auto bg-neutral-800" />
        </FadeIn>
        <FadeInStagger faster>
          <ul
            role="list"
            className="mt-10 grid grid-cols-2 gap-x-8 gap-y-10 lg:grid-cols-5"
          >
            {technology.map(([technology, logo]) => (
              <li key={technology}>
                <FadeIn>
                  <img src={logo} alt={technology} />
                </FadeIn>
              </li>
            ))}
          </ul>
        </FadeInStagger>
      </Container>
    </div>
  )
}

function AboutUs() {
  return (
    <>
    <div className="mx-auto max-w-7xl lg:flex lg:items-center lg:justify-end mt-24 sm:mt-32 lg:mt-40">
      <SectionIntro
        title="About Ognis Digital"
        className=""
      >
        <p className='mb-6'>
          At Ognis Digital, we are a dynamic team of passionate technologists, innovators, and problem solvers, committed to driving positive change through technology. Our journey began with a vision to revolutionize the IT industry and empower businesses to thrive in the digital era.
        </p>
        <p>
          Founded by a group of tech enthusiasts in India, Ognis Digital was established with a singular purpose – to bridge the gap between businesses and cutting-edge technology. Over the years, we have evolved into a leading IT company, serving clients globally and catering to diverse industry verticals.
        </p>
      </SectionIntro>
          <div className="flex justify-center lg:w-1/2 lg:justify-end lg:pl-12">
            <FadeIn className="w-[33.75rem] flex-none lg:w-[45rem]">
              <StylizedImage
                src={imageAbout}
                sizes="(min-width: 1024px) 41rem, 31rem"
                className="justify-center lg:justify-end"
              />
            </FadeIn>
          </div>
      </div>
    </>
  )
}
function CaseStudies({ caseStudies }) {
  return (
    <>
      <SectionIntro
        title="Harnessing technology for a brighter future"
        className="mt-24 sm:mt-32 lg:mt-40"
      >
        <p>
          We believe technology is the answer to the world’s greatest
          challenges. It’s also the cause, so we find ourselves in bit of a
          catch 22 situation.
        </p>
      </SectionIntro>
      <Container className="mt-16">
        <FadeInStagger className="grid grid-cols-1 gap-8 lg:grid-cols-3">
          {caseStudies.map((caseStudy) => (
            <FadeIn key={caseStudy.href} className="flex">
              <article className="relative flex w-full flex-col rounded-3xl p-6 ring-1 ring-neutral-950/5 transition hover:bg-neutral-50 sm:p-8">
                <h3>
                  <Link href={caseStudy.href}>
                    <span className="absolute inset-0 rounded-3xl" />
                    <img
                      src={caseStudy.logo}
                      alt={caseStudy.client}
                      className="h-16 w-16"
                    />
                  </Link>
                </h3>
                <p className="mt-6 flex gap-x-2 text-sm text-neutral-950">
                  <time
                    dateTime={caseStudy.date.split('-')[0]}
                    className="font-semibold"
                  >
                    {caseStudy.date.split('-')[0]}
                  </time>
                  <span className="text-neutral-300" aria-hidden="true">
                    /
                  </span>
                  <span>Case study</span>
                </p>
                <p className="mt-6 font-display text-2xl font-semibold text-neutral-950">
                  {caseStudy.title}
                </p>
                <p className="mt-4 text-base text-neutral-600">
                  {caseStudy.description}
                </p>
              </article>
            </FadeIn>
          ))}
        </FadeInStagger>
      </Container>
    </>
  )
}

function Services() {
  return (
    <>
      <SectionIntro
        eyebrow="Services"
        title="We can assist you in discovering, analyzing, and taking advantage of new opportunities."
        className="mt-24 sm:mt-32 lg:mt-40"
      >
        <p>
        {`At to Ognis Digital, we're dedicated to empowering businesses like yours with cutting-edge digital solutions tailored to your needs. `}
        </p>
      </SectionIntro>
      <Container className="mt-16">
        <div className="lg:flex lg:items-center lg:justify-end">
          <div className="flex justify-center lg:w-1/2 lg:justify-end lg:pr-12">
            <FadeIn className="w-[33.75rem] flex-none lg:w-[45rem]">
              <StylizedImage
                src={imageLaptop}
                sizes="(min-width: 1024px) 41rem, 31rem"
                className="justify-center lg:justify-end"
              />
            </FadeIn>
          </div>
          <List className="mt-16 lg:mt-0 lg:w-1/2 lg:min-w-[33rem] lg:pl-4">
            <ListItem title="Website Development">
            Elevate your online presence with our seasoned full-stack developers. We craft intuitive, responsive websites that captivate audiences and drive results.
            </ListItem>
            {/* <ListItem title="Application Development">
            Harness the power of innovation with our team of skilled developers proficient in the latest app frameworks. From concept to deployment, we bring your ideas to life with precision and expertise.
            </ListItem> */}
            <ListItem title="E-commerce Solutions">
            Unlock new opportunities for growth with our comprehensive e-commerce solutions. From storefront design to payment integration, we provide end-to-end support for your online business.
            </ListItem>
            <ListItem title="Mobile App Development">
            Deliver seamless experiences to your customers on the go with our mobile app development services. Whether iOS or Android, we create intuitive apps that leave a lasting impression.
            </ListItem>
            <ListItem title="UI/UX Designs">
            Stand out in the digital landscape with our captivating UI/UX designs. We combine creativity with user-centric principles to deliver interfaces that resonate with your audience.
            </ListItem>
          </List>
        </div>
      </Container>
    </>
  )
}

export const metadata = {
  description:
    'We are a development Ognis working at the intersection of design and technology.',
}

// export default async function Home() {
  function Home() {

  return (
    <>
      <Container className="mt-24 sm:mt-32 md:mt-56">
        <FadeIn className="max-w-4xl">
          <h1 className="font-display text-5xl font-medium tracking-tight text-neutral-950 [text-wrap:balance] sm:text-7xl">
          Crafting Bespoke Web & App Solutions Partnering with You for Digital Success  
          </h1>
          <p className="mt-6 text-xl text-neutral-600">
          Elevate your digital presence with cutting-edge mobile solutions. Welcome to Ognis Digital, where we redefine the standards of custom web and app development. Choose extraordinary over ordinary, and embark on a journey of innovation with us.
          </p>
        </FadeIn>
      </Container>

      <Clients />

      <AboutUs />

      <Testimonial
        className="mt-24 sm:mt-32 lg:mt-40"
        client={{ name: 'Sparcle Ceramique', logo: logoSparcle }}
      >
        Ognis Digital transformed our vision into a stunning, user-friendly website that perfectly represents our brand. Their expertise in design and digital marketing significantly boosted our online presence. Highly recommend their outstanding service!
      </Testimonial>

      <Services />

      <ContactSection />
    </>
  )
}
export default Home;



