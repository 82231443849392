import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import routes from './routes'; // Adjust the path as per your project structure
import ErrorBoundary from './ErrorBoundary';
import './styles/tailwind.css';
import Layout from './layout/Layout';
import ScrollToTop from './layout/ScrollToTop';
function App() {
  return (
    <Router>
      <ErrorBoundary>
      <Layout>
        <ScrollToTop>
      <Routes>
        {routes.map(route => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Routes>
      </ScrollToTop>
      </Layout>
      </ErrorBoundary>
    </Router>
  );
}

export default App;
