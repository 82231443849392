import { ContactSection } from '../components/ContactSection'
import { Container } from '../components/Container'
import { FadeIn } from '../components/FadeIn'
import { PageIntro } from '../components/PageIntro'
import { StylizedImage } from '../components/StylizedImage'
import imageApplicationDevelopment from '../images/service/application-development.jpg'
import imageWebsiteDevelopment from '../images/service/website-development.jpg'
import imageEcommerceSolutions from '../images/service/ecommerce-solutions.jpg'
import imageMobileAppDevelopment from '../images/service/mobile-app-development.jpg'
import imageUIUXDesign from '../images/service/ui-ux-design.jpg'
import imageDigitalMarketing from '../images/service/digital-marketing.jpg'
import imageDevOpsServices from '../images/service/devops.jpg'
import imageDomainMailingSolutions from '../images/service/domain-solutions.jpg'

function Section({ title, image, children }) {
  return (
    <Container className="group/section [counter-increment:section]">
      <div className="lg:flex lg:items-center lg:justify-end lg:gap-x-8 lg:group-even/section:justify-start xl:gap-x-20">
        <div className="flex justify-center">
          <FadeIn className="w-[33.75rem] flex-none lg:w-[45rem]">
            <StylizedImage
              {...image}
              sizes="(min-width: 1024px) 41rem, 31rem"
              className="justify-center lg:justify-end lg:group-even/section:justify-start"
            />
          </FadeIn>
        </div>
        <div className="mt-12 lg:mt-0 lg:w-[37rem] lg:flex-none lg:group-even/section:order-first">
          <FadeIn>
            <div
              className="font-display text-base font-semibold before:text-neutral-300 before:content-['/_'] after:text-neutral-950 after:content-[counter(section,decimal-leading-zero)]"
              aria-hidden="true"
            />
            <h2 className="mt-2 font-display text-3xl font-medium tracking-tight text-neutral-950 sm:text-4xl">
              {title}
            </h2>
            <div className="mt-6">{children}</div>
          </FadeIn>
        </div>
      </div>
    </Container>
  )
}

function ApplicationDevelopment() {
  return (
    <Section title="Application Development" image={{ src: imageApplicationDevelopment }}>
      <div className="space-y-6 text-base text-neutral-600">
        <p>
        Our team of experienced developers specializes in creating robust, scalable, and high-performing applications tailored to meet your unique business needs. We employ the latest technologies and agile methodologies to deliver custom software solutions that drive efficiency and innovation. Whether you need a desktop application, enterprise software, or a bespoke solution, we have the expertise to bring your vision to life. Our process involves detailed requirement analysis, design, development, testing, and deployment to ensure your application not only meets but exceeds your expectations.
        </p>
        <p>
        We pride ourselves on our ability to adapt to the ever-changing technology landscape, ensuring that your application remains relevant and competitive. From integrating advanced features like AI and machine learning to ensuring seamless user experiences, we are committed to delivering top-notch applications. Partner with us to leverage technology in transforming your business operations, enhancing productivity, and achieving your strategic objectives.
        </p>
      </div>
    </Section>
  )
}

function WebsiteDevelopment() {
  return (
    <Section title="Website Development" image={{ src: imageWebsiteDevelopment, shape: 1 }}>
      <div className="space-y-6 text-base text-neutral-600">
        <p>
      We offer comprehensive website development services designed to build engaging, user-friendly, and SEO-optimized websites. From simple landing pages to complex web applications, our developers and designers work collaboratively to create websites that are not only visually appealing but also function seamlessly across all devices. Our responsive designs ensure a consistent user experience, whether accessed on a desktop, tablet, or smartphone, helping you reach a broader audience effectively.
      </p>
      <p>
      Understanding that your website is often the first interaction customers have with your brand, we focus on creating impactful designs and compelling content. Our development process includes thorough testing to ensure speed, security, and reliability, providing you with a website that stands out in today’s digital world. Let us help you establish a strong online presence that captivates your audience and drives business growth.
      </p>
      </div>
    </Section>
  )
}

function EcommerceSolutions() {
  return (
    <Section title="E-commerce Solutions" image={{ src: imageEcommerceSolutions, shape: 2 }}>
      <div className="space-y-6 text-base text-neutral-600">
        <p>
        Our e-commerce solutions are tailored to help you succeed in the competitive online marketplace. We provide end-to-end services including store design, development, payment gateway integration, and security enhancements. Our solutions are designed to enhance user experience, streamline operations, and boost sales, whether you’re starting a new online store or looking to improve an existing one. We understand the complexities of e-commerce and are here to help you navigate them with ease.
        </p>
        <p>
        Our team works closely with you to understand your business goals and target audience, ensuring your e-commerce platform is customized to meet your specific needs. From intuitive navigation to secure checkout processes, we focus on every detail that contributes to a seamless shopping experience. Trust us to provide a robust e-commerce solution that not only attracts customers but also turns them into loyal patrons, driving sustained growth for your business.
        </p>
      </div>
    </Section>
  )
}

function MobileAppDevelopment() {
  return (
    <Section title="Mobile App Development" image={{ src: imageMobileAppDevelopment, shape: 1 }}>
      <div className="space-y-6 text-base text-neutral-600">
        <p>
        Stay ahead in the mobile-first world with our custom mobile app development services. We create high-quality, intuitive mobile applications for both iOS and Android platforms. Our team focuses on delivering apps that offer seamless performance, rich user experiences, and innovative functionalities. From concept to deployment, we ensure your app stands out in the crowded app marketplace, providing you with a competitive edge.
        </p>
        <p>
        Our mobile app development process is comprehensive, covering everything from initial strategy and design to development, testing, and post-launch support. We prioritize user experience and performance, ensuring your app is not only visually appealing but also fast and reliable. Whether you need a consumer-facing app or an enterprise solution, we are dedicated to helping you leverage mobile technology to achieve your business objectives and enhance customer engagement.
        </p>
      </div>
    </Section>
  )
}

function UIUXDesign() {
  return (
    <Section title="UI/UX Design" image={{ src: imageUIUXDesign, shape: 2 }}>
      <div className="space-y-6 text-base text-neutral-600">
        <p>
        Our UI/UX design services are dedicated to creating aesthetically pleasing and user-centric designs that enhance user satisfaction and drive engagement. We conduct thorough research and usability testing to ensure that our designs not only look great but also provide an intuitive and efficient user experience. Our team of designers and usability experts work together to understand your audience and craft interfaces that meet their needs and preferences.
        </p>
        <p>
        We believe that great design is not just about appearance but also about functionality and usability. Our approach involves close collaboration with you to ensure that the design aligns with your brand identity and business goals. Whether you need a new design for a mobile app, a website, or any digital product, our focus is on creating interfaces that are both beautiful and easy to use. Partner with us to create digital interfaces that resonate with your audience and elevate your brand.
        </p>
      </div>
    </Section>
  )
}

function DigitalMarketing() {
  return (
    <Section title="Digital Marketing" image={{ src: imageDigitalMarketing, shape: 1 }}>
      <div className="space-y-6 text-base text-neutral-600">
        <p>
        Boost your online presence and reach your target audience with our comprehensive digital marketing services. We offer SEO, PPC, social media marketing, content marketing, and email marketing strategies tailored to your business goals. Our data-driven approach ensures maximum ROI and helps you stay ahead of the competition. We understand the dynamics of digital marketing and use our expertise to craft strategies that increase visibility, drive traffic, and convert leads into customers.
        </p>
        <p>
        Our digital marketing services are designed to provide you with a competitive edge in the digital landscape. We start with a thorough analysis of your current online presence and market position, identifying opportunities for growth and improvement. Our team then develops and implements customized marketing strategies that align with your business objectives. From optimizing your website for search engines to creating engaging social media campaigns, we are dedicated to helping you achieve measurable results and sustained growth.
        </p>
      </div>
    </Section>
  )
}

function DevOpsServices() {
  return (
    <Section title="DevOps Services" image={{ src: imageDevOpsServices, shape: 2 }}>
      <div className="space-y-6 text-base text-neutral-600">
        <p>
        Optimize your development and operations processes with our DevOps services. We help businesses enhance their IT infrastructure, streamline workflows, and accelerate software delivery through automation and continuous integration/continuous deployment (CI/CD) practices. Our DevOps experts work closely with your team to create a culture of collaboration and efficiency, driving faster and more reliable releases. We aim to bridge the gap between development and operations, ensuring a seamless workflow and improved productivity.
        </p>
        <p>
        Our DevOps services include assessment, strategy planning, implementation, and ongoing support. We tailor our approach to fit your specific needs, helping you leverage the latest tools and practices to achieve your goals. Whether you are looking to improve your existing processes or implement a new DevOps strategy, we provide the expertise and support you need to succeed. Trust us to help you transform your development and operations, enhancing agility, reliability, and performance.
        </p>
      </div>
    </Section>
  )
}

function DomainMailingSolutions() {
  return (
    <Section title="Domain & Mailing Solutions" image={{ src: imageDomainMailingSolutions, shape: 2 }}>
      <div className="space-y-6 text-base text-neutral-600">
        <p>
        Secure your online identity and enhance your communication with our domain and mailing solutions. We provide domain registration, management, and professional email services that are reliable and easy to use. Our solutions ensure your business maintains a professional online presence with personalized email addresses, robust security, and seamless integration. Whether you are a small business or a large enterprise, we have the expertise to manage your domains and email systems efficiently.
        </p>
        <p>
        Our domain and mailing solutions are designed to provide you with peace of mind, knowing that your online presence is in good hands. We offer a range of services including domain privacy protection, DNS management, and email hosting with advanced security features. Our team is here to support you every step of the way, ensuring that your domains and email systems are set up correctly and run smoothly. Let us help you maintain a secure and professional online identity.
        </p>
      </div>
    </Section>
  )
}

export const metadata = {
  title: 'Our Process',
  description:
    'We believe in efficiency and maximizing our resources to provide the best value to our clients.',
}

function Services() {
  return (
    <>
      <PageIntro eyebrow="Our services" title="What We Offer">
        <p>
        We offer a wide range of high-quality services designed to meet your business needs. Our expert team is dedicated to providing innovative solutions that drive success and growth. Explore our services below to find the perfect solution for your business.
        </p>
      </PageIntro>

      <div className="mt-24 space-y-24 [counter-reset:section] sm:mt-32 sm:space-y-32 lg:mt-40 lg:space-y-40">
        <ApplicationDevelopment />
        <WebsiteDevelopment />
        <EcommerceSolutions />
        <MobileAppDevelopment/>
        <UIUXDesign/>
        <DigitalMarketing/>
        <DevOpsServices/>
        <DomainMailingSolutions/>
      </div>
      <ContactSection />
    </>
  )
}

export default Services;