import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Process from './pages/Process';
import Services from './pages/Services';
import NotFound from './pages/NotFound';

const routes = [
  { path: '/', name: 'Home', element: <Home /> },
  { path: '/about', name: 'About', element: <About /> },
  { path: '/contact', name: 'Contact', element: <Contact /> },
  { path: '/process', name: 'Process', element: <Process /> },
  { path: '/services', name: 'Services', element: <Services /> },
  { path: '*', name: 'Not Found', element: <NotFound /> },
];

export default routes;
