import { Button } from '../components/Button'
import { ContactSection } from '../components/ContactSection'
import { Container } from '../components/Container'
import { GridList, GridListItem } from '../components/GridList'
import { PageIntro } from '../components/PageIntro'
import { Testimonial } from '../components/Testimonial'
import { SectionIntro } from '../components/SectionIntro'

import logoSparcle from '../images/clients/sparcle/logo.png'

function Culture() {
  return (
    <div className="mt-24 rounded-4xl bg-neutral-950 py-24 sm:mt-32 lg:mt-40 lg:py-32">
      <SectionIntro
        title="Why choose Ognis Digital?"
        invert
      >
        <p>
        We believe in more than just providing a service; we aim to revolutionize the way you experience technology. Here’s why you should choose Ognis for all your digital needs:
        </p>
      </SectionIntro>
      <Container className="mt-16">
        <GridList>
          <GridListItem title="Expertise" invert>
          Our team comprises seasoned professionals with years of experience in software development across diverse industries.
          </GridListItem>
          <GridListItem title="Collaboration" invert>
          We believe in fostering strong partnerships with our clients, working together as a cohesive team towards a shared goal.
          </GridListItem>
          <GridListItem title="Innovation" invert>
          We stay abreast of the latest technologies and trends, ensuring that your software solution is cutting-edge and future-proof.
          </GridListItem>
          <GridListItem title="Reliability" invert>
          With a proven track record of delivering successful projects on time and within budget, you can trust us to get the job done right.          </GridListItem>
        </GridList>

        <p className='text-neutral-300 text-center max-w-3xl mt-16 mx-auto'>Experience the Ognis difference today and discover a new world of possibilities for your business. <br/> Get in touch with us to learn more about how we can help you achieve your goals.</p>
        <div className='mx-auto mt-8 text-center'>
        <Button to="/contact" invert>
            Get in touch
          </Button>
          </div>
      </Container>
    </div>
  )
}

export const metadata = {
  title: 'About Us',
  description:
    'We believe that our strength lies in our collaborative approach, which puts our clients at the center of everything we do.',
}

function About() {
  return (
    <>
      <PageIntro eyebrow="About us" title="Ognis Digital">
        <p>
          We believe that our strength lies in our collaborative approach, which
          puts our clients at the center of everything we do.
        </p>
        <div className="mt-10 max-w-3xl space-y-6 text-base">
          <p>
          Ognis Digital is an India-based software development and consulting company that provides clients with customised software, web, and mobile application requirements in a variety of fields by utilising the most up-to-date technology and experience. We are proficient in codifying difficult customer needs using our best thought process. We have a strong team of IT professionals that are committed to fulfilling the needs of our clients on time and with great quality. We provide a wide range of solutions across the world, with a focus on how to realise thoughts through innovations and technology.           </p>
          <p>
          {`The company was created by a group of enthusiastic IT professionals who wanted to break free from the routine and develop a company that would operate in the market not only for the purpose of financial success but also for the sake of IT itself. Thus, the company's objective was defined: to contribute to the forward-thinking change of society through software creation. This has resulted in highly beneficial long-term working partnerships all across the world. Our rigorous project process was developed to ensure that our projects are finished on time, under budget, and to the great satisfaction of our clients.`}
          </p>
        </div>
      </PageIntro>

      <Culture />
    <Testimonial
        client={{ name: 'Sparcle Ceramique', logo: logoSparcle }}
        bgWhite={true}
      >
        Ognis Digital transformed our vision into a stunning, user-friendly website that perfectly represents our brand. Their expertise in design and digital marketing significantly boosted our online presence. Highly recommend their outstanding service!
      </Testimonial>

     <ContactSection className="mt-10 sm:mt-10 lg:mt-10" />
    </>
  )
}
export default About;